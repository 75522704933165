import appConfig from "configs/app.config"
import MapService from "./MapService"

export async function apiGetNotificationCount() {
    return MapService.fetchData({
        url: '/notification/count',
        method: 'get',
    })
}

export async function apiGetPlaces(data) {
    return MapService.fetchData({
        url: '/autocomplete',
        method: 'get',
        params: {
            input: data,
            api_key: appConfig.olaMapKey,
          },
          headers: {
            "X-Request-Id": Date.now(), // You can generate a unique ID for tracking requests
          },
    })
}

export async function apiGetSearchResult(data) {
    return MapService.fetchData({
        url: '/autocompleteTest',
        method: 'post',
        data,
    })
}
