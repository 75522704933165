// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth ,connectAuthEmulator} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configurationmo
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZRpCpM5G4KjZ7uG8-g0iRplqlz1ddZIE",
  authDomain: "aitcfirebase.firebaseapp.com",
  databaseURL: "https://aitcfirebase-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "aitcfirebase",
  storageBucket: "aitcfirebase.appspot.com",
  messagingSenderId: "368513238376",
  appId: "1:368513238376:web:5bce12cd5a7c752c8202dd",
  measurementId: "G-HQ751RW92X"
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// if (process.env.NODE_ENV === 'development') {
//   connectAuthEmulator(auth, 'http://localhost:9099');
// }

//export default firebaseConfig

//Initialize Firebase

