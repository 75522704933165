import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/sign-in',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiGetAllBooking(data) {
    return ApiService.fetchData({
      //  url: '/bookings',
       url: '/getAllBooking',
        
        method: 'post',
        data,
        
    })
}

export async function apiUpdateBookingStatus(data) {
    return ApiService.fetchData({
        url: '/updateBookingStatus',
        method: 'post',
        data,
        
    })
}


export async function apiSignInWithPhone(data) {
    return ApiService.fetchData({
        url: '/sendVerificationCode.',
        method: 'post',
        data,
        
    })
}

export async function apiUpdateDriveDetails(data) {
    return ApiService.fetchData({
        url: '/updateDriveDetails',
        method: 'post',
        data,
        
    })
}

export async function apiGetBookingsAdmin(data) {
    return ApiService.fetchData({
        url: '/getBookingAdmin',
        method: 'post',
        data
        
        
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
