import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import appConfig from 'configs/app.config';
import { apiGetPlaces } from 'services/CommonService';
import { useDispatch } from 'react-redux';
import axios from 'axios';

export const initialState = {
    mapsPredictions: [],
    loading: false,
    pickupGeoData: {},
    dropGeoData: {},
    tripRoutingData:{}
}

export const fetchPlaces = createAsyncThunk('common/fetchPlaces',  async (data, { dispatch })=> {

    console.log("response.databeforeeeeeeee",data.query)
 
    try {
        const response = await axios.get(
          `https://api.olamaps.io/places/v1/autocomplete`,
          {
            params: {
              input: data.query,
              api_key: appConfig.olaMapKey,
            },
            headers: {
              "X-Request-Id": Date.now(), // You can generate a unique ID for tracking requests
            },
          }
        );
        console.log("response.data",response.data)
        return response.data
       // setSuggestions(response.data.predictions || []);
      } catch (error) {
        console.error("Error fetching autocomplete suggestions:", error);
      }



})


export const dataFromOlaMaps = createAsyncThunk('maps/dataFromOlaMaps',  async (data, { dispatch })=> {
 
  //  console.log("response.databeforeeeeeeee",data.query)
    const response = await apiGetPlaces(data.query);

  // console.log("response.data",response)

 //  dispatch(setPredictedPlaces(response.data.predictions))

   return response.data.predictions;


})


export const mapSlice = createSlice({
    name: 'maps/state',
    initialState,
  reducers: {
    setPredictedPlaces: (state, action) => {
      state.mapsPredictions = action.payload
    },
    setPickupGeoData: (state, action) => {
      state.pickupGeoData = action.payload
    },
    setTripRoutingData: (state, action) => {
      state.tripRoutingData = action.payload
    },
    setDropGeoData: (state, action) => {
      state.dropGeoData = action.payload
    },
  },
    extraReducers: (builder) => {
        //  console.log("Inside sendPromptChatGPT"+builder);
        builder
          .addCase(dataFromOlaMaps.fulfilled, (state, action) => {

         //  console.log("Inside dataFromOlaMaps.fulfilled"+JSON.stringify(action.payload.predictions));
            state.mapsPredictions = action.payload;
            state.loading = false;
        })
        .addCase(dataFromOlaMaps.pending, (state, action) => {
          // state.mapsPredictions = action.payload;
            state.loading = false;
        })
    
    
        
    
    }
})



export const { setPredictedPlaces ,setPickupGeoData,setDropGeoData,setTripRoutingData} = mapSlice.actions

export default mapSlice.reducer
