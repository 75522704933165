//// syncMiddleware.js
//import { updateDataInFirebase } from './firebaseUtils'; // Define your Firebase-related functions
import {initializeApp} from '@firebase/app';

import { getFirestore, collection, getDocs,addDoc,updateDoc,deleteDoc,doc } from '@firebase/firestore/lite';
import deepParseJson from 'utils/deepParseJson';
import {app} from 'configs/firebaseConfig';
import { PERSIST_STORE_NAME } from 'constants/app.constant'








   const firestore = getFirestore(app);
  //  const usersCollection = collection(firestore, 'messages');
  //   const documentRef = doc(usersCollection,  messageId);

// Middleware that syncs data to Firebase after the Redux store updates
const synchMiddleware = (store) => (next) => (action) => {
  // Call the next middleware
 const result = next(action);
//  const firestore = getFirestore();
//  const usersCollection = collection(firestore, 'messages');





//   if(action.type === 'manageTrip/common/setBookingFromAuthUser'){

//     //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
//       const messageCollection = collection(firestore, 'users');
//       let documentRef="";
//       let documentRefProjectId=""
   
//       if(store.getState().auth.user.uid){
//         documentRef = doc(messageCollection, store.getState().auth.user.uid);
//       }

//       console.log("Document ref=="+documentRef)
   
    
   
//      try{
//      //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
//        const stateToPersist = deepParseJson(JSON.stringify(store.getState().bookings.manageTrip.bookingData));
//        console.log("Document ref==99999999"+stateToPersist)
//     //    if(documentRef && stateToPersist && stateToPersist!=="")
//     //    {
//     //      updateDoc(documentRef,stateToPersist);
//     //    }
//         updateDoc(userDocRef, {
//         bookingData: arrayUnion({
//             data:data.data,
//             bookingId: data.bookingId,
//             tripRoutesData: store.getState().bookings.maps.tripRoutingData,
//             status: 'unconfirmed',
//         })
//     });
        
//    console.log('Document successfully updated!');
//      } catch (error) {
//        console.error('Error updating document:', error);
//      }
//      }

 


  return result;
};

export default synchMiddleware;
