import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { collection, addDoc,getFirestore ,query,where,getDocs,arrayUnion} from 'firebase/firestore';
import {  getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import store from 'store';

import {app} from 'configs/firebaseConfig';
import { update } from 'lodash';
import { apiGetAllBooking, apiGetBookingsAdmin, apiUpdateBookingStatus, apiUpdateDriveDetails } from 'services/AuthService';

export const initialState = {
   bookingData: [],
   loading: false,
   bookingGuestData: {},
   priceFlag:false,
   profile:{},
   profileLoad:false,
   allUsers:[],
   bookingDataAdmin:[],
   loadQuote:false,
   errors:'',
   isProfileUpdated:true,
   addClient:false
}



export const registerBooking = createAsyncThunk('manageTrip/registerBooking', async (data, { dispatch }) => {
    try {
        const firestore = getFirestore(app);
        const bookingsCollectionRef = collection(firestore, 'bookings');
        const docRef = await addDoc(bookingsCollectionRef, data);

        console.log('Document written with ID: ', store.getState().auth.user.uid);

        // Ensure userId is defined
        if (!store.getState().auth.user.uid) {
            throw new Error('user is undefined');
        }

        // Create a query to find the user document where uid matches data.userId
        const usersCollectionRef = collection(firestore, 'users');
        const q = query(usersCollectionRef, where('uid', '==', store.getState().auth.user.uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            throw new Error('User not found');
        }

        // Assuming there is only one document with the matching uid
        const userDocRef = querySnapshot.docs[0].ref;
       

        // Update the users collection with the bookingData
        await updateDoc(userDocRef, {
            bookingData: arrayUnion({
                data:data.data,
                bookingId: data.bookingId,
                tripRoutesData: store.getState().bookings.maps.tripRoutingData,
                status: 'unconfirmed',
            })
        });

      //  dispatch(clearBookingData());
     //   dispatch(updateUserBooking(docRef.id));

        console.log('Document successfully updated!');
    } catch (error) {
        console.error('Error updating document:', error);
        throw error;
    }
});



export const updateUserProfileDb = createAsyncThunk('manageTrip/updateUserProfileDb', async (data, { dispatch }) => {
    try {
        const firestore = getFirestore(app);
      
        console.log("test data "+store.getState().auth.user.uid);
        console.log("test data==============dataatataat "+data);
      
        const usersCollectionRef = collection(firestore, 'users');
        const q = query(usersCollectionRef, where('uid', '==', store.getState().auth.user.uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            throw new Error('User not found');
        }

        // Assuming there is only one document with the matching uid
        const userDocRef = querySnapshot.docs[0].ref;
       

         // Update the users collection with the bookingData
         await updateDoc(userDocRef, {
            profile: {
               ...data
            }
        });
       
        console.log('Document successfully updated!');
            return data;

      //  dispatch(clearBookingData());
     //   dispatch(updateUserBooking(docRef.id));

       
    } catch (error) {
        console.error('Error updating document:', error);
        throw error;
    }
});



export const bookingEnteringAuth = createAsyncThunk('manageTrip/bookingEnteringAuth', async (data, { dispatch }) => {
    try {
        const firestore = getFirestore(app);
        const bookingsCollectionRef = collection(firestore, 'bookings');
        const docRef = await addDoc(bookingsCollectionRef, data);

        console.log('Document written with ID: ', store.getState().auth.user.uid);

        // Ensure userId is defined
        if (!store.getState().auth.user.uid) {
            throw new Error('user is undefined');
        }

        // Create a query to find the user document where uid matches data.userId
        const usersCollectionRef = collection(firestore, 'users');
        const q = query(usersCollectionRef, where('uid', '==', store.getState().auth.user.uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            throw new Error('User not found');
        }

        // Assuming there is only one document with the matching uid
        const userDocRef = querySnapshot.docs[0].ref;
       

        // Update the users collection with the bookingData
        await updateDoc(userDocRef, {
            bookingData: arrayUnion({
                data:data.data,
                bookingId: docRef.id,
                tripRoutesData: data.tripRoutesData,
                status: 'unconfirmed',
            })
        });

        dispatch(setBookingFromAuthUser({
            data:data.data,
            bookingId: data.bookingId,
            tripRoutesData: data.tripRoutesData,
            status: 'unconfirmed',
        }))

      //  dispatch(clearBookingData());
     //   dispatch(updateUserBooking(docRef.id));

        console.log('Document successfully updated!');
    } catch (error) {
        console.error('Error updating document:', error);
        throw error;
    }
});

export const geAllBookingsFromApi = createAsyncThunk('manageTrip/geAllBookingsFromApi', async (data, { dispatch }) => {
  
    const response = await apiGetAllBooking({"userId":store.getState().auth.user.uid});

    //console.log("all adataa==="+response.data)

    return response.data;

})



export const getBookingsSelectedUser = createAsyncThunk('manageTrip/getBookingsSelectedUser', async (data, { dispatch }) => {
  
  //  const response = await apiGetAllBooking({"userId":data});

    const response = await apiGetAllBooking({});

    //console.log("all adataa==="+response.data)

    return response.data;

})

export const getAllBookingsAdmin = createAsyncThunk('manageTrip/getAllBookingsAdmin', async (data, { dispatch }) => {
  
    const response = await apiGetBookingsAdmin(data);

    //console.log("all adataa==="+response.data)

    return response.data;

})

export const updateBookingSatus = createAsyncThunk('manageTrip/updateBookingSatus', async (data, { dispatch }) => {
  
    const response = await apiUpdateBookingStatus(data);

    //console.log("all adataa==="+response.data)

    return response.data;

})


export const updateDriverDetails = createAsyncThunk('manageTrip/updateDriverDetails', async (data, { dispatch }) => {
  
    const response = await apiUpdateDriveDetails(data);

    //console.log("all adataa==="+response.data)

    return response.data;

})

export const bookingEnteringSignedUser = createAsyncThunk('manageTrip/bookingEnteringSignedUser', async (data, { dispatch }) => {
    try {
        const firestore = getFirestore(app);
        const bookingsCollectionRef = collection(firestore, 'bookings');
        const docRef = await addDoc(bookingsCollectionRef, data);

        console.log('Document written with ID: ', store.getState().auth.user.uid);

        // Ensure userId is defined
        if (!store.getState().auth.user.uid) {
            throw new Error('user is undefined');
        }

        // Create a query to find the user document where uid matches data.userId
        const usersCollectionRef = collection(firestore, 'users');
        const q = query(usersCollectionRef, where('uid', '==', store.getState().auth.user.uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            throw new Error('User not found');
        }

        // Assuming there is only one document with the matching uid
        const userDocRef = querySnapshot.docs[0].ref;
       

        // Update the users collection with the bookingData
        await updateDoc(userDocRef, {
            bookingData: arrayUnion(
              docRef.id
              
            )
        });

        // dispatch(setBookingFromAuthUser({
        //     data:data.data,
        //     bookingId: data.bookingId,
        //     tripRoutesData: data.tripRoutesData,
        //     status: 'unconfirmed',
        // }))

      //  dispatch(clearBookingData());
     //   dispatch(updateUserBooking(docRef.id));

        console.log('Document successfully updated!');
    } catch (error) {
        console.error('Error updating document:', error);
        throw error;
    }
});

export const manageTrip = createSlice({
    name: 'manageTrip/common',
    initialState,
    reducers: {
     
        setBookingData: (state, action) => {
            state.bookingGuestData = action.payload
        },
        setBookingDataFromFirebase: (state, action) => {
            if(action.payload && action.payload.length>0){
                state.bookingData =[...state.bookingData,...action.payload] 
            }
           
        },
        setAllUsers: (state, action) => {
          
            if(action.payload){
                state.allUsers=action.payload
            }
           
           
        },
        setProfileData: (state, action) => {
            state.profile =action.payload
        },
        setGetPriceFlag: (state, action) => {
            state.priceFlag =action.payload;
        },
        clearGuestData: (state, action) => {
            state.bookingGuestData ={};
        },

        addClinedData: (state, action) => {
            state.bookingDataAdmin =[...state.bookingDataAdmin,action.payload]
        },
        setBookingFromAuthUser: (state, action) => {
            state.bookingData =[...state.bookingData,action.payload] 
            // if(action.payload){
            //     state.bookingGuestData = {}
            // }
        },
        clearBookingData: (state, action) => {
            state.bookingData = []
        },
    },
    extraReducers: (builder) => {
        //  console.log("Inside sendPromptChatGPT"+builder);
        builder
          .addCase(registerBooking.fulfilled, (state, action) => {

         //  console.log("Inside dataFromOlaMaps.fulfilled"+JSON.stringify(action.payload.predictions));
          //  state.mapsPredictions = action.payload;
            state.loading = false;
        })
        .addCase(registerBooking.pending, (state, action) => {
          // state.mapsPredictions = action.payload;
            state.loading = true;
        })
        .addCase(bookingEnteringAuth.fulfilled, (state, action) => {

            //  console.log("Inside dataFromOlaMaps.fulfilled"+JSON.stringify(action.payload.predictions));
             //  state.mapsPredictions = action.payload;
               state.loading = false;
           })
           .addCase(bookingEnteringAuth.pending, (state, action) => {
             // state.mapsPredictions = action.payload;
               state.loading = true;
           })
           .addCase(updateUserProfileDb.fulfilled, (state, action) => {

            //  console.log("Inside dataFromOlaMaps.fulfilled"+JSON.stringify(action.payload.predictions));
             //  state.mapsPredictions = action.payload;
             state.profile=action.payload
               state.profileLoad = false;
           })
           .addCase(updateUserProfileDb.pending, (state, action) => {
             // state.mapsPredictions = action.payload;
               state.profileLoad = true;
           })
           .addCase(updateUserProfileDb.rejected, (state, action) => {
               console.error('Update user profile failed:', action.error.message);
                 state.error = action.error.message;
                 state.profileLoad= false;
             })
             .addCase(geAllBookingsFromApi.pending, (state, action) => {
                state.loading = true;
               })
               .addCase(geAllBookingsFromApi.rejected, (state, action) => {
               state.loading = false;
               })
             .addCase(geAllBookingsFromApi.fulfilled, (state, action) => {
               state.bookingData=action.payload.bookingData;
               state.profile=action.payload.profile

            //   console.log("all adataa==="+action.payload.profile)
            //    if(!state.profile.firstName){
            //     isProfileUpdated=false;
            //    }
               state.loading = false;
              })
              .addCase(getBookingsSelectedUser.fulfilled, (state, action) => {
               // state.bookingDataAdmin=action.payload.bookingData;
              //  state.profile=action.payload.profile
               })
               .addCase(getAllBookingsAdmin.fulfilled, (state, action) => {
                state.bookingDataAdmin=action.payload;
                state.loading=false;
              //  state.profile=action.payload.profile
               })
               .addCase(getAllBookingsAdmin.pending, (state, action) => {
                state.loading=true;
              //  state.profile=action.payload.profile
               })
               .addCase(getAllBookingsAdmin.rejected, (state, action) => {
                state.loading=false;
              //  state.profile=action.payload.profile
               })
               .addCase(updateBookingSatus.pending, (state, action) => {
                state.loadQuote=true;
              //  state.profile=action.payload.profile
               })
               .addCase(updateBookingSatus.fulfilled, (state, action) => {
                state.loadQuote=false;
              //  state.profile=action.payload.profile
               }) 
               .addCase(updateBookingSatus.rejected, (state, action) => {
                state.loadQuote=false;
                state.errors= action.payload.error;
              //  state.profile=action.payload.profile
               })
               .addCase(updateDriverDetails.pending, (state, action) => {
                state.loadQuote=true;
              //  state.profile=action.payload.profile
               })
               .addCase(updateDriverDetails.fulfilled, (state, action) => {
                state.loadQuote=false;
              //  state.profile=action.payload.profile
               }) 
               .addCase(updateDriverDetails.rejected, (state, action) => {
                state.loadQuote=false;
                state.errors= action.payload.error;
              //  state.profile=action.payload.profile
               })
               .addCase(bookingEnteringSignedUser.fulfilled, (state, action) => {
             state.bookingGuestData={}
              //  state.profile=action.payload.profile
               })
        
    
        
    
    }
})

export const { setBookingData,setAllUsers,addClinedData,clearGuestData,clearBookingData,setProfileData,setBookingDataFromFirebase,setBookingFromAuthUser,setGetPriceFlag} = manageTrip.actions

export default manageTrip.reducer
